import request from '@/utils/request'
const baseURL = '/baseapi'
const UploadApi = {
  Token: '/admin/upload/token',
  AddImagelibrary: '/admin/upload/addImagelibrary'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function getToken () {
  return request({
    baseURL: baseURL,
    url: UploadApi.Token,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function addImagelibrary (parameter) {
  return request({
    baseURL: baseURL,
    url: UploadApi.AddImagelibrary,
    method: 'post',
    data: parameter
  })
}
